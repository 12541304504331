import React from 'react';
import { graphql } from 'gatsby';
import { StructuredTextDocument } from 'react-datocms/dist/esm';
import { render } from 'datocms-structured-text-to-plain-text';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
// eslint-disable-next-line import/no-cycle
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Accordion, { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '../../../primitives/accordion';
import Background from '../../../primitives/backgrounds';
import Typography from '../../../primitives/typography';
import { colors } from '../../../primitives/colors';
import StyledLink from '../../../primitives/styled-link';
import { breakpoints } from '../../../primitives/tokens';
import { getLink } from '../../../primitives/link';
import JsonLd from '../../../json-ld';

export type FaqSetProps = Queries.FaqSetPropsFragment;

const FaqSet = ({ richTitle, items, link, isStructuredData }: FaqSetProps): JSX.Element => (
    <>
        {isStructuredData && (
            <JsonLd>
                {{
                    '@context': 'https://schema.org/',
                    '@type': 'FAQPage',
                    mainEntity: items && items.map((item) => {
                        if (!item) return null;

                        const {
                            title,
                            richText,
                        } = item;
                        return {
                            '@type': 'Question',
                            name: title,
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: render(richText),
                            },
                        };
                    }).filter(x => !!x),
                }}
            </JsonLd>
        )}
        <Background
            breakpoints={{
                dt: {
                    backgroundColor: 'shadesWhite',
                    clip: 'bottom',
                },
            }}
            css={{
                zIndex: 1,
                marginTop: '52px',
            }}
        >
            <Container css={{
                paddingBottom: '52px',
                [breakpoints.mb]: {
                    paddingBottom: '24px',
                },
            }}
            >
                <Row>
                    <Col breakpoints={{ dt: { span: 6 } }}>
                        <Typography
                            fontSize={{
                                dt: 4053,
                                mb: 2429,
                            }}
                            fontWeight="light"
                            override
                            css={{
                                [breakpoints.mb]: {
                                    textAlign: 'center',
                                    paddingBottom: '36px',
                                },
                            }}
                        >
                            <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
                        </Typography>
                    </Col>
                    <Col breakpoints={{ dt: { span: 6 } }}>
                        <Accordion>
                            {items && items.map((item, idx) => (
                                <AccordionItem css={{
                                    borderBottom: idx !== items.length - 1 ? `1px solid ${colors.grey100}` : 'none',

                                }}
                                >
                                    <AccordionButton css={{
                                        padding: '22px 0',
                                        [breakpoints.tb]: {
                                            padding: '17px 0',
                                        },
                                        '[data-state=open] &': {
                                            color: colors.primaryOrange,
                                        },
                                    }}
                                    >
                                        <Typography
                                            fontSize={{
                                                dt: 1822,
                                                mb: 1627,
                                            }}
                                        >
                                            {item?.title}
                                        </Typography>
                                        <AccordionIcon css={{
                                            '[data-state=open] &': {
                                                color: colors.primaryOrange,
                                            },
                                        }}
                                        />
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <Typography
                                            fontSize={{
                                                dt: 1627,
                                                tb: 1420,
                                                mb: 1226,
                                            }}
                                            css={{
                                                paddingBottom: '22px',
                                                p: {
                                                    marginBottom: '12px',
                                                },
                                            }}
                                            override
                                        >
                                            <CustomStructuredTextRenderer
                                                data={item?.richText as unknown as StructuredTextDocument}
                                            />
                                        </Typography>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                        {link && link.length > 0 && (
                            <div css={{
                                paddingTop: '40px',
                                [breakpoints.mb]: {
                                    textAlign: 'center',
                                    paddingTop: '28px',
                                },
                            }}
                            >
                                <StyledLink to={getLink(link[0]?.link)}>
                                    {link[0].title}
                                </StyledLink>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </Background>
    </>
);

export default FaqSet;

export const query = graphql`
    fragment FaqSetProps on DatoCmsFaqSet {
        isStructuredData
        richTitle {
            value
            links
            blocks
        }
        items {
            title
            richText {
                blocks
                value
                links
            }
        }
        link {
            ...LinkWithTitleProps
        }
    }
`;
